import React from "react"
import { Link } from "gatsby"
import { Header, Footer, SEO } from "../components"
import { thanksBadPaymentImage } from "../assets"
import { urlWithSession } from "../utils"

export default function Thanks() {
  return (
    <>
      <SEO></SEO>
      <Header />
      <div className="thanks-page">
        <div className="header-thanks">
          <div className="icon">
            <img src={thanksBadPaymentImage} alt="pago incorrecto"></img>
          </div>
          <div className="title">¡Lo sentimos!</div>
          <div className="message">No se pudo completar la reserva.</div>
        </div>
        <div className="button-content">
          <Link to={urlWithSession("/")}>
            <div className="button">VOLVER AL HOME</div>
          </Link>
        </div>
      </div>
      <Footer />
    </>
  )
}
